@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #050f10;
    --color-bg-variant: #164043;
    --color-primary: #40bcc3;
    --color-primary-variant: #2f8a90;
    --color-primary-variant-transparent: rgba(47, 188, 195, 0.3);
    --color-text: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease-in-out;

    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-text);
    line-height: 1.7;
    background-image: url(../../assets/background-pattern.jpg);
    background-color: var(--color-bg-variant);
    background-attachment: fixed;
}

/* =============== General Styles =============== */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section>h5 {
    text-align: center;
    color: var(--color-light);
}

section>h2 {
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-text);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-text);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* =============== Media Queries (Medium) =============== */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* =============== Media Queries (Small) =============== */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section>h2 {
        margin-top: 2rem;
    }
}