.flag {
    opacity: 0.7;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: var(--transition);
    filter: grayscale(1);
}

.flag:hover {
    filter: grayscale(0.5);
}

.selected {
    filter: grayscale(0);
}