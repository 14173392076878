.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-primary-variant-transparent);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item-links {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.portfolio__item:hover {
    background: var(--color-bg-variant);
    border-color: var(--color-primary-variant);
    cursor: default;
}

.portfolio__item-image {
    object-fit: contain;
    border-radius: 1.5rem;
    overflow: hidden;
}

/* =============== Media Queries (large) =============== */

@media screen and (min-width: 1024px) {}

/* =============== Media Queries (Medium) =============== */

@media screen and (max-width: 1024px) {}

/* =============== Media Queries (Small) =============== */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;

    }
}