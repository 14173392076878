header {
    min-height: 950px;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    position: relative;
}

/* ====== header CTA ====== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ====== header socials ====== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ====== header image ====== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    mask-image: linear-gradient(rgba(0, 0, 0, 1.0) 90%, transparent 100%);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.me>img {

    margin-top: -4rem;
    margin-left: -20px;
    width: 22rem;
    border-radius: 12rem 12rem 0 0;
}

/* ====== scroll down ====== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =============== Media Queries (large) =============== */

@media screen and (min-width: 1024px) {
    header {
        height: 100vh;
        padding-top: 6rem;
    }
}

/* =============== Media Queries (Medium) =============== */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
        padding-top: 4rem;
    }
}

/* =============== Media Queries (Small) =============== */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        padding-top: 2rem;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}